<template>
    <b-container>
        <b-row>
            <b-col cols="12" md="7">
                <u-ul-list :list="list" variant="decimal"/>
            </b-col>
            <b-col cols="12" md="5">
                <b-card>
                    <basic-form :form="form" form-class="form-short" :submit-button="submitButton"/>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Form} from "../../classes/form/";
    import {Input, Checkbox, Select} from "../../classes/form/input";
    import {InputGroup} from "../../classes/form/inputGroup";
    import {required, minLength, maxLength} from 'vuelidate/lib/validators'

    export default {
        name: "Form",
        data() {
            return {
                list: [
                    'Выберите форму бизнеса и заполните онлайн-анкету.',
                    'Получите заполненные документы для регистрации и открытия счета на электронную почту.',
                    'Подайте документы в регистрирующий орган (исполком).',
                    'При выборе банка укажите Банк ВТБ (Беларусь). Документы на<br>открытие счета регистрирующий орган отправит в банк самостоятельно.<br>А по остальным вопросам мы свяжемся с вами.',
                ],
                submitButton: {
                    variant: 'u-button',
                    text: 'Выбрать форму бизнеса',
                    size: 'small',
                    classButton: 'd-block mx-auto px-md-5',
                    type: 'submit',
                },
            }
        },
        computed: {
            form() {
                return new Form(
                    'Выбрать форму бизнеса',
                    [
                        new InputGroup(
                            (new Input(
                                'tn',
                                '',
                                '5',
                                'd-none',
                                '',
                                '',
                                '',
                            )).setValidatorOptions({
                                required
                            }),
                            (new Input(
                                'type',
                                '',
                                'Выбрать форму бизнеса',
                                'd-none',
                                '',
                                '',
                                '',
                            )).setValidatorOptions({
                                required
                            }),
                            (new Input('lead_user_name', 'ФИО', '', 'col-md-12',)
                            ).setValidatorOptions(
                                {
                                    required,

                                }
                            ),
                            (new Input('lead_user_phone', 'Телефон', '+375', 'col-md-12', '', '+375',)
                            ).setValidatorOptions(
                                {
                                    required,
                                    alpha: val => /^[+]*[(]?[0-9][)]?[-\s0-9]*$/.test(val),
                                    minLength: minLength(13),
                                    maxLength: maxLength(16)
                                }
                            ),
                            (new Input('lead_user_locality', 'Населенный пункт', '', 'col-md-12',)
                            ).setValidatorOptions(
                                {
                                    required,

                                }
                            ),
                            (new Checkbox('lead_user_agree',
                                    'Оставляя заявку, вы принимаете условия <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">Пользовательского соглашения и Политики конфиденциальности</a>.',
                                    'lead_user_agree', 'col-md-12')
                            ).setValidatorOptions(
                                {required}
                            ),
                        ),
                    ],
                );
            },
        },
        components: {
            BasicForm: () => import(/* webpackChunkName: 'basic-form-in-header'*/'../BasicForm/'),
            UUlList: () => import(/* webpackChunkName: 'u-ul-list'*/'../UUlList'),
        }
    }
</script>
<style scoped lang="scss">
    .card {
        background: white;
        box-shadow: 0 4px 104px rgba(183, 197, 205, 0.25);
        border-radius: 3px;
        border: none;
    }
</style>